import React from 'react'
import { Layout } from 'react-admin'
import AppBar from './appbar'
import { useAuthenticated } from 'react-admin'

const CustomLayout = props => {
  return <Layout {...props} appBar={AppBar} />
}

export default CustomLayout
