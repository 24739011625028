import React from 'react'
import PropTypes from 'prop-types'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  ReferenceField,
  DateField,
  FunctionField,
  withTranslate,
  Confirm,
  useNotify,
  useDataProvider,
} from 'react-admin'
import compose from 'recompose/compose'
import { getIdFromIRI, formatMoney } from '../../../../utils'
import { getCommercialPaymentsReport, setCommercialPaymentsPaidOut } from '../../../../api'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'

const useStyles = makeStyles({
  bulkActionButtonsContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    marginBottom: 18,
  },
})

const getIdFromIRIInternal = getIdFromIRI

const CommercialPaymentsBulkActionButtons = ({ translate, ...props }) => {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const classes = useStyles()

  const downloadPaymentsReport = async () => {
    setLoading(true)
    const success = await getCommercialPaymentsReport({ ids: props.selectedIds })
    setLoading(false)
    if (success) {
      notify('downloadInit')
    } else {
      notify('downloadError', 'warning')
    }
  }

  const setPaidOut = async () => {
    setOpen(false)
    setLoading(true)
    const data = { ids: props.selectedIds }
    const success = setCommercialPaymentsPaidOut(data)
    if (success) {
      const promises = _.map(props.selectedIds, (id) => dataProvider.getOne(props.resource, { id }))
      await Promise.all(promises)
      notify('setCommercialPaymentsPaidOutSuccess')
    } else {
      notify('setCommercialPaymentsPaidOutError', 'warning')
    }
    setLoading(false)
  }

  return (
    <div className={classes.bulkActionButtonsContainer}>
      {loading ? <CircularProgress size={24} style={{ marginRight: 20 }} /> : null}
      <Button variant="contained" color="primary" onClick={() => setOpen(true)} disabled={loading}>
        {translate('resources.comercial_payments.updateStatus')}
      </Button>
      <Button variant="contained" color="primary" onClick={() => downloadPaymentsReport()} style={{ marginLeft: 10 }} disabled={loading}>
        {translate('resources.comercial_payments.exportXML')}
      </Button>
      <Confirm
        isOpen={open}
        loading={false}
        title={translate('resources.comercial_payments.updateStatusTitle')}
        content={translate('resources.comercial_payments.updateStatusMessage')}
        onConfirm={setPaidOut}
        onClose={() => setOpen(false)}
      />
    </div>
  )
}

CommercialPaymentsBulkActionButtons.propTypes = {
  translate: PropTypes.func,
  selectedIds: PropTypes.array,
  resource: PropTypes.string,
}

const ListResource = ({ translate, ...props }) => {
  return (
    <List {...props} title="comercial_payments" exporter={false} bulkActionButtons={<CommercialPaymentsBulkActionButtons translate={translate} />}>
      <Datagrid rowClick="show">
        <ReferenceField
          source="comercial"
          reference="users"
          link={(record, reference) => {
            return `/${reference}/${getIdFromIRIInternal(record.comercial)}/show`
          }}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="payment"
          reference="payments"
          link={(record, reference) => {
            return `/${reference}/${getIdFromIRIInternal(record.payment)}/show`
          }}
        >
          <TextField source="id" />
        </ReferenceField>
        <TextField source="organizationName" />
        <FunctionField source="amount" render={(v) => formatMoney(v.amount)} />
        <FunctionField source="status" render={(record) => translate(`resources.payments.status.${record.status}`)} />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  )
}

ListResource.propTypes = {
  translate: PropTypes.func,
}

const enharce = compose(withTranslate)

export default enharce(ListResource)
