export const getIdFromIRI = uri => {
  let parts = uri.split('/')

  return parts[parts.length - 1]
}

export const formatMoney = amount => {
  return new Intl.NumberFormat('es-es', {
    style: 'currency',
    currency: 'EUR'
  }).format(amount / 100)
}
