import React from 'react'
import { Show, ReferenceField, SimpleShowLayout, TextField, BooleanField } from 'react-admin'

const ShowResource = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField
        source="organization"
        reference="organizations"
        link={(record, reference) => {
          return `/${reference}/${record.id}/show`
        }}
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="currentPeriodStart" />
      <TextField source="currentPeriodEnd" />
      <BooleanField source="active" />
    </SimpleShowLayout>
  </Show>
)

export default ShowResource
