import React from 'react'
import PropTypes from 'prop-types'
import { Edit, SimpleForm, NumberInput, SelectInput, Toolbar, SaveButton, withTranslate } from 'react-admin'
import { PAYMENT_STATUS_NEW, PAYMENT_STATUS_COMPLETED, PAYMENT_STATUS_FAILED } from '../../../../config/constants'
const CommercialPaymentsToolbar = props => (
  <Toolbar {...props}>
    <SaveButton redirect="show" submitOnEnter={true} />
  </Toolbar>
)

const EditResource = ({ translate, ...props }) => (
  <Edit {...props}>
    <SimpleForm redirect="show" toolbar={<CommercialPaymentsToolbar />}>
      <SelectInput
        source="status"
        choices={[
          { id: PAYMENT_STATUS_NEW, name: translate(`resources.payments.status.${PAYMENT_STATUS_NEW}`) },
          { id: PAYMENT_STATUS_COMPLETED, name: translate(`resources.payments.status.${PAYMENT_STATUS_COMPLETED}`) },
          { id: PAYMENT_STATUS_FAILED, name: translate(`resources.payments.status.${PAYMENT_STATUS_FAILED}`) }
        ]}
      />
      <NumberInput source={'amount'} label={'amountWithCurrency'} />
    </SimpleForm>
  </Edit>
)

EditResource.propTypes = {
  translate: PropTypes.func
}

export default withTranslate(EditResource)
