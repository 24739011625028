import React from 'react'
import { Datagrid, Show, SimpleShowLayout, DateField, TextField, BooleanField, ReferenceArrayField } from 'react-admin'

const ShowResource = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="businessName" />
      <TextField source="document" />
      <TextField source="address" />
      <TextField source="city" />
      <TextField source="postalCode" />
      <TextField source="province" />
      <ReferenceArrayField source="clients" reference="users">
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="surname" />
          <TextField source="phoneNumber" />
        </Datagrid>
      </ReferenceArrayField>
      <BooleanField source="isDeleted" />
      <DateField source="created" />
      <DateField source="updated" />
      <ReferenceArrayField source="users" reference="users">
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="surname" />
          <TextField source="phoneNumber" />
        </Datagrid>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
)

export default ShowResource
