export default {
  main: '#00A0E1',
  secondary: '#00A0E1',
  pink: '#F1BDC8',
  yellow: '#F9BA20',
  black: '#000000',
  white: '#FFFFFF',
  grey: '#C5C5C5',
  red: '#FB344C',
  shadowWhite: 'rgba(255, 255, 255, 0.8);'
}
