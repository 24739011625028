import { configureAxiosDefaults, instance as axios } from './utils'
import { stringify } from 'query-string'
import { BASE_URL } from '../config/api'
import FileSaver from 'file-saver'

export function configureToken(token) {
  configureAxiosDefaults('Authorization', token ? `Bearer ${token}` : null)
}

export function configureLanguage(locale) {
  configureAxiosDefaults('Accept-Language', locale)
}

// AUTH
export const login = ({ username, password }) => {
  const url = `${BASE_URL}/api/login_check`
  return axios.post(url, { username, password })
}

// PAYMENTS
export const getCommercialPaymentsReport = async (params) => {
  try {
    const url = `${BASE_URL}/api/comercial_payments/generatexml?${stringify(params, { arrayFormat: 'bracket' })}`
    const res = await axios.get(url)
    const xmlBlob = new Blob([res.data], { type: 'text/xml;charset=utf-8' })
    FileSaver.saveAs(xmlBlob, 'report.xml')
    return true
  } catch (e) {
    return false
  }
}

export const setCommercialPaymentsPaidOut = async (data) => {
  try {
    const url = `${BASE_URL}/api/comercial_payments/updatestatus`
    await axios.put(url, data)
    return true
  } catch (e) {
    return false
  }
}

export default axios
