import React from 'react'
import { List, Datagrid, DateField, TextField, BooleanField, ShowButton } from 'react-admin'

const BulkActionButtons = () => <div />
const ListResource = (props) => {
  return (
    <List {...props} title="users" exporter={false} bulkActionButtons={<BulkActionButtons />}>
      <Datagrid rowClick="show" isRowSelectable={() => false}>
        <TextField source="id" />
        <TextField source="phoneNumber" />
        <TextField source="name" />
        <TextField source="surname" />
        <TextField source="country" />
        <BooleanField source="enabled" />
        <BooleanField source="superAdmin" />
        <DateField source="lastLogin" />
        <DateField source="created" />
        <TextField source="referrer.phoneNumber" />
        <ShowButton />
      </Datagrid>
    </List>
  )
}

export default ListResource
