import { fetchUtils } from 'react-admin'
import { stringify } from 'query-string'

import { BASE_URL, ENTRY_POINT } from '../../config/api'
import _ from 'lodash'

const apiUrl = ENTRY_POINT
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/ld+json' })
  }
  // add your own headers here
  const token = localStorage.getItem('token')
  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`)
  }

  return fetchUtils.fetchJson(url, options)
}

export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      // sort: JSON.stringify([field, order]),
      // range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      // EXPECTED GET URL: /api/users?page=1&itemsPerPage=1&order[id]=asc
      itemsPerPage: perPage,
      page: page,
      filter: JSON.stringify(params.filter),
    }
    const url = `${apiUrl}/${resource}?${stringify(query, {})}&order[${field}]=${order.toLowerCase()}`

    return httpClient(url).then(({ json }) => {
      const res = {
        data: _.get(json, 'hydra:member', []),
        total: _.get(json, 'hydra:totalItems', []),
      }
      return res
    })
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    // "params.ids" is IRI not ID, FIX TO WORK WITH IRI
    return Promise.all(params.ids.map((id) => httpClient(_.isNumber(id) ? `${apiUrl}/${resource}/${id}` : `${BASE_URL}${id}`))).then((responses) => {
      let res = responses.map((response) => response.json)
      res = res.map((e) => {
        return { ...e, id: e['@id'] }
      })
      return { data: res }
    })
  },

  getManyReference: (resource, params) => {
    // "params.ids" is IRI not ID, FIX TO WORK WITH IRI
    return Promise.all(params.ids.map((id) => httpClient(_.isNumber(id) ? `${apiUrl}/${resource}/${id}` : `${BASE_URL}${id}`))).then((responses) => {
      const res = { data: responses.map((response) => response.json) }
      return res
    })
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    // "params.ids" is IRI not ID, FIX TO WORK WITH IRI
    const allRequest = Promise.all(
      params.ids.map((id) =>
        httpClient(`${BASE_URL}${id}`, {
          method: 'DELETE',
          body: JSON.stringify(params.data),
        })
      )
    ).then((responses) => ({
      data: responses.map((response) => response.json),
    }))
    return allRequest
  },
}
