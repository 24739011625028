import spanishMessages from '@blackbox-vision/ra-language-spanish'

export default {
  ...spanishMessages,
  'Expired JWT Token': 'La sesión ha caducado',
  'Invalid JWT Token': 'La sesión ha caducado',

  resources: {
    users: {
      name: 'Usuarios',
      fields: {
        name: 'Nombre',
        surname: 'Apellidos',
        zipCode: 'Código postal',
        phoneNumber: 'Teléfono',
        country: 'País',
        birthDate: 'Fecha de nacimiento',
        lastLogin: 'Último inicio de sesión',
        enabled: 'Activado',
        created: 'Creado el',
        superAdmin: 'Admin',
        organizations: 'Organizaciones',
        themes: 'Temas',
        messages: 'Mensajes',
        referrer: {
          phoneNumber: 'Teléfono referido',
        },
      },
    },
    themes: {
      name: 'Temas',
      fields: {
        name: 'Nombre',
        description: 'Descripción',
        participants: 'Participantes',
        admins: 'Administradores',
        organization: 'Organización',
        readOnly: 'Sólo lectura',
        endDate: 'Fecha de finalización',
        polls: 'Encuestas',
        isDeleted: 'Está borrado',
        messages: 'Mensajes',
        invitations: 'Invitaciones',
      },
    },
    organizations: {
      name: 'Organizaciones',
      fields: {
        name: 'Nombre',
        invitations: 'Invitaciones',
        clients: 'Clientes',
        isDeleted: 'Está borrado',
        created: 'Creado el',
        updated: 'Actualizado el',
        enabledMembers: 'Miembros activos',
        users: 'Usuarios',
        themes: 'Temas',
        businessName: 'Razón social',
        document: 'NIF',
        address: 'Dirección',
        city: 'Municipio',
        postalCode: 'Código postal',
        province: 'Provincia',
      },
    },
    subscriptions: {
      name: 'Suscripciones',
      fields: {
        organization: 'Organización',
        currentPeriodStart: 'Fecha inicio',
        currentPeriodEnd: 'Fecha fin',
        active: 'Activo',
      },
    },
    payments: {
      name: 'Pagos',
      fields: {
        participantsCount: 'Nº Participantes',
        amount: 'Cantidad',
        status: 'Estado',
        createdAt: 'Creado',
        updatedAt: 'Actualizado',
        subscription: 'Suscripción',
      },
      status: {
        new: 'Pendiente de Pago',
        completed: 'Pagado',
        failed: 'Ha ocurrido un Error',
      },
    },
    comercial_payments: {
      name: 'Pagos comerciales',
      updateStatus: 'Marcar como pagados',
      updateStatusTitle: '¿Marcar como pagados?',
      updateStatusMessage: 'Los pagos seleccionados van a marcarse como pagados. ¿Desea continuar?',
      exportXML: 'Exportar XML',
      fields: {
        comercial: 'Comercial',
        payment: 'Pago',
        organizationName: 'Organización',
        amount: 'Cantidad',
        status: 'Estado',
        createdAt: 'Creado',
        updatedAt: 'Actualizado',
      },
    },
  },

  users: 'Usuarios',
  themes: 'Temas',
  organizations: 'Organizaciones',
  directs: 'Directos',
  amountWithCurrency: 'Cantidad (en céntimos)',
  downloadInit: 'Iniciando descarga del fichero',
  downloadError: 'Ha ocurrido un error con la descarga del fichero',
  setCommercialPaymentsPaidOutSuccess: 'Elementos seleccionados marcados como pagados',
  setCommercialPaymentsPaidOutError: 'Ha ocurrido un error',
}
