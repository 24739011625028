import _ from 'lodash'
import { roles } from '../../config/api'
import jwtDecode from 'jwt-decode'
import * as api from '../../api'

const login = async ({ username, password }) => {
  const loginRes = await api.login({ username: username, password })
  const token = _.get(loginRes, 'data.token', null)
  const decoded = jwtDecode(token)
  const permissions = _.get(decoded, 'roles', [])
  const isAdmin = permissions.includes(roles.ROLE_ADMIN)

  if (isAdmin) {
    api.configureToken(token)
    localStorage.setItem('token', token)
    return Promise.resolve(true)
  }

  throw new Error('Invalid Login')
}

const logout = async () => {
  api.configureToken(null)
  localStorage.clear()
}

const checkError = (params) => {
  const status = _.get(params, 'response.status', -1)
  const message = _.get(params, 'message', '')
  if (status === 401 || status === 403 || message === 'Invalid JWT Token') {
    localStorage.clear()
    return Promise.reject()
  }
  return Promise.resolve()
}

const checkAuth = () => {
  if (localStorage.getItem('token') === null) {
    return Promise.reject('Invalid Access')
  }

  return Promise.resolve()
}

export const getAuthToken = () => localStorage.getItem('token')
export const getUserId = () => localStorage.getItem('userId')
export const getUserName = () => localStorage.getItem('userName')

export default {
  login,
  logout,
  checkError,
  checkAuth,
  getPermissions: () => Promise.resolve(true),
}
