import React from 'react'
import { List, Datagrid, TextField, DateField, ShowButton, BooleanField, ReferenceField } from 'react-admin'

import { getIdFromIRI } from '../../../../utils'

const getIdFromIRIInternal = getIdFromIRI

const BulkActionButtons = () => <div />
const ListResource = (props) => {
  return (
    <List {...props} title="subscritpions" exporter={false} bulkActionButtons={<BulkActionButtons />}>
      <Datagrid rowClick="show" isRowSelectable={() => false}>
        <ReferenceField
          source="organization"
          reference="organizations"
          link={(record, reference) => {
            return `/${reference}/${getIdFromIRIInternal(record.organization)}/show`
          }}
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="currentPeriodStart" />
        <DateField source="currentPeriodEnd" />
        <BooleanField source="active" />
        <ShowButton />
      </Datagrid>
    </List>
  )
}

export default ListResource
