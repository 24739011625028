import colors from '../../../assets/colors'

export default {
  main: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    background: 'white'
  },
  avatar: {},
  icon: {},
  card: {
    marginTop: 0,
    minWidth: '300px',
    '@media (min-width: 768px)': {
      border: `1px solid ${colors.black}`
    }
  }
}
