import { createMuiTheme } from '@material-ui/core/styles'
import colors from '../assets/colors'

const theme = createMuiTheme({
  colors,
  palette: {
    primary: {
      main: colors.main,
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: colors.secondary
    },
    error: {
      main: colors.red
    }
  },
  overrides: {
    MuiFormControl: {
      root: {
        width: '100%!important'
      }
    }
  }
})

export default theme
