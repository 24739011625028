import React from 'react'
import PropTypes from 'prop-types'
import { Show, DateField, ReferenceField, SimpleShowLayout, TextField, FunctionField, withTranslate } from 'react-admin'
import { getIdFromIRI, formatMoney } from '../../../../utils'

const getIdFromIRIInternal = getIdFromIRI

const ShowResource = ({ translate, ...props }) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField
        source="comercial"
        reference="users"
        link={(record, reference) => {
          return `/${reference}/${getIdFromIRIInternal(record.comercial)}/show`
        }}
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="organizationName" />
      <FunctionField source="amount" render={v => formatMoney(v.amount)} />
      <FunctionField source="status" render={record => translate(`resources.payments.status.${record.status}`)} />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
)

ShowResource.propTypes = {
  translate: PropTypes.func
}

export default withTranslate(ShowResource)
