import list from './list'
import show from './show'
//import create from './create'
//import edit from './edit'

export default {
  list,
  show
  //create,
  //edit
}
