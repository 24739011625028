import enMessages from 'ra-language-english'
import esMessages from 'ra-language-spanish'
import i18nMessages from '../../assets/i18n'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { resolveBrowserLocale } from 'react-admin'

const messages = {
  es: { ...esMessages, ...i18nMessages.es },
  en: { ...enMessages, ...i18nMessages.en }
}

const i18nProvider = polyglotI18nProvider(locale => (messages[locale] ? messages[locale] : messages.es), resolveBrowserLocale())

export default i18nProvider
