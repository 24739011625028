import React from 'react'
import { List, Datagrid, TextField, BooleanField, DateField, ShowButton } from 'react-admin'

const BulkActionButtons = () => <div />
const ListResource = (props) => {
  return (
    <List {...props} title="organizations" exporter={false} bulkActionButtons={<BulkActionButtons />}>
      <Datagrid rowClick="show" isRowSelectable={() => false}>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="enabledMembers" />
        <DateField source="created" />
        <BooleanField source="isDeleted" />
        <ShowButton />
      </Datagrid>
    </List>
  )
}

export default ListResource
