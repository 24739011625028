import axios from 'axios'
import * as ApiConfig from '../config/api'
import * as WSLogger from './logger'

export const instance = axios.create({
  baseURL: ApiConfig.BASE_URL,
  headers: { 'Content-type': 'application/json' }
})

instance.interceptors.request.use(
  config => {
    WSLogger.logCall(config.method, config.url, config)
    return config
  },
  err => Promise.reject(err)
)

instance.interceptors.response.use(
  function(response) {
    WSLogger.logResponse(response.config.method, response.config.url, response)
    return response
  },
  error => Promise.reject(error)
)

export function configureAxiosDefaults(header, value) {
  instance.defaults.headers.common[header] = value
}
