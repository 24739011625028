import React from 'react'
import { ENTRY_POINT } from '../../../config/api'
import authProvider from '../../../providers/authProvider'
import { Login, Organizations, Users, ComercialPayments, Subscriptions, Payments } from '../../pages'
import { Admin, Resource } from 'react-admin'
import theme from '../../../config/theme'
import Layout from '../layout'
import dataProvider from '../../../providers/dataProvider'
import i18nProvider from '../../../providers/i18nProvider'
import UserIcon from '@material-ui/icons/People'
import BusinessIcon from '@material-ui/icons/Business'
import Payment from '@material-ui/icons/Payment'
import MoneyOff from '@material-ui/icons/MoneyOff'
import Money from '@material-ui/icons/Money'
import { configureToken } from '../../../api'
const App = () => {
  React.useEffect(() => {
    const token = localStorage.getItem('token')
    if (token !== null) {
      configureToken(token)
    }
  }, [])
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={Layout}
      entrypoint={ENTRY_POINT}
      theme={theme}
      loginPage={Login}
      i18nProvider={i18nProvider}
    >
      <Resource name="users" {...Users} icon={UserIcon} />

      <Resource name="organizations" {...Organizations} icon={BusinessIcon} />
      <Resource name="subscriptions" {...Subscriptions} icon={Payment} />
      <Resource name="comercial_payments" {...ComercialPayments} icon={MoneyOff} />
      <Resource name="payments" {...Payments} icon={Money} />
      <Resource name="messages" />
      <Resource name="polls" />
      <Resource name="poll_answers" />
      <Resource name="invitations" />
      <Resource name="organization_unsubscribes" />
    </Admin>
  )
}

export default App
