import React from 'react'
import PropTypes from 'prop-types'
import { List, Datagrid, TextField, ShowButton, DateField, ReferenceField, FunctionField, withTranslate } from 'react-admin'

import { getIdFromIRI, formatMoney } from '../../../../utils'

const getIdFromIRIInternal = getIdFromIRI

const BulkActionButtons = () => <div />
const ListResource = ({ translate, ...props }) => {
  return (
    <List {...props} title="payments" exporter={false} bulkActionButtons={<BulkActionButtons />}>
      <Datagrid rowClick="show" isRowSelectable={() => false}>
        <TextField source="id" />
        <TextField source="participantsCount" />
        <FunctionField source="amount" render={(v) => formatMoney(v.amount)} />
        <FunctionField source="status" render={(record) => translate(`resources.payments.status.${record.status}`)} />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <ReferenceField
          source="subscription"
          reference="subscriptions"
          link={(record, reference) => {
            return `/${reference}/${getIdFromIRIInternal(record.subscription)}/show`
          }}
        >
          <TextField source="id" />
        </ReferenceField>
        <ShowButton />
      </Datagrid>
    </List>
  )
}

ListResource.propTypes = {
  translate: PropTypes.func,
}

export default withTranslate(ListResource)
