import React from 'react'
import PropTypes from 'prop-types'
import { Show, SimpleShowLayout, DateField, TextField, FunctionField, withTranslate } from 'react-admin'
import { formatMoney } from '../../../../utils'

const ShowResource = ({ translate, ...props }) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="participantsCount" />
      <TextField source="subscription" />
      <FunctionField source="amount" render={v => formatMoney(v.amount)} />
      <FunctionField source="status" render={record => translate(`resources.payments.status.${record.status}`)} />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
)

ShowResource.propTypes = {
  translate: PropTypes.func
}

export default withTranslate(ShowResource)
