import React from 'react'
import { Show, Datagrid, SimpleShowLayout, TextField, DateField, BooleanField, ReferenceArrayField } from 'react-admin'

const ShowResource = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="phoneNumber" />
      <TextField source="name" />
      <TextField source="surname" />
      <TextField source="country" />
      <BooleanField source="enabled" />
      <BooleanField source="superAdmin" />
      <DateField source="lastLogin" />
      <DateField source="created" />
      <TextField source="referrer.phoneNumber" />

      <ReferenceArrayField source="organizations" reference="organizations">
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
        </Datagrid>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
)

export default ShowResource
